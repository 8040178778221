import $ from 'jquery'

$(function () {
  $('textarea[id^="user_profiles_attributes_0_about_this_location"]').on('keyup paste input', function () {
    let maxlength = 110
    let noteCharacters = $('.note-characters')

    $(this).val($(this).val().substring(0, maxlength))

    let remainingChars = maxlength - $(this).val().length
    let noteText = `${remainingChars } characters remaining`

    if (noteCharacters.length === 0) {
      $('.user_profiles_about_this_location .bootstrapForm_input').append('<p class="note-characters alert"></p>')
    }

    noteCharacters.css('display', 'block')
    noteCharacters.removeClass('warning')

    if (remainingChars <= 20) {
      noteCharacters.addClass('warning')
    }

    noteCharacters.html(noteText)
  })
})
