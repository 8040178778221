import $ from 'jquery'

$(document).ready(function() {
  function videoPlay(wrapper) {
    const iframe = wrapper.find('.js-videoIframe')
    const src = iframe.attr('src')
    wrapper.addClass('videoActive')
    iframe.attr('src', `${src}&autoplay=1`)
  }

  $('.js-videoPoster').click(function (e) {
    e.preventDefault()
    videoPlay($(this).parent('.js-video'))
  })

  $(document).on('click', '.js-lightboxVideo', function() {
    let lightbox = `<div class="lightboxVideo">
      <div class="lightboxVideo_content">
        <div class="lightboxVideo_inner">
          <iframe src="${$(this).data('video-src')}" allow="autoplay; fullscreen"></iframe>
        </div>
      </div>
    </div>`

    if (!$('.lightboxVideo').length) {
      $('body').append(lightbox)
    }
  })

  $(document).on('click', '.lightboxVideo', function() {
    $(this).remove()
  })
})
