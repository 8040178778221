import $ from 'jquery'
import 'slick-carousel'

$(function() {
  $('[data-age-verification-button]').click(function(e) {
    let today = new Date()
    let limit = new Date(
      parseInt($('#ageVerification_year').val()) +
        parseInt($(this).attr('data-years')),
      $('#ageVerification_month').val() - 1,
      $('#ageVerification_day').val()
    )
    console.log($('#ageVerification_year').val())
    if(today < limit) {
      window.location = $(this).attr('data-url')
    }
    else{
      $('#mainContent').show()
      $('.ageVerification_excl').remove()
    }
    e.preventDefault()
  })

  $('[data-age-verification-accept]').click(function(e) {
    $('#mainContent').show()
    $('[data-age-verification-overlay]').remove()
    e.preventDefault()
    $('.acceptedWastesSlider').slick('setPosition')
  })

  $('[data-age-verification-decline]').click(function(e) {
    e.preventDefault()
    window.location = $(this).attr('data-url')
  })
})
