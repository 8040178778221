import $ from 'jquery'

function isScrolledIntoView(elem) {
  let docViewTop = $(window).scrollTop(),
    docViewBottom = docViewTop + $(window).height(),
    elemTop = $(elem).offset().top,
    elemBottom = elemTop + $(elem).height()

  return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop))
}

window.addEventListener('DOMContentLoaded', () => {
  if ($('.terracycle_differences').length) {
    $(window).scroll(function() {
      if (isScrolledIntoView($('.tcDifferencesWorm'))) {
        setTimeout(() => {
          $('#worm-msg').removeClass('visible')
          $('#worm-msg-new').addClass('visible')
        }, 3000)
      }

      if (isScrolledIntoView($('.tcDifferencesWaste'))) {
        $('.tcDifferencesWaste').addClass('scaled')
        setTimeout(() => $('.tcDifferencesWaste').addClass('showedText'), 1000)
      }
    })
  }
})
