import $ from 'jquery'

$(document).ready(function () {
  function toggleTabsAndAccordion() {
    let windowWidth = $(window).width()

    // If screen width is less than 991px, convert tabs to accordion
    if (windowWidth < 991) {
      $('.brigadeAccordion_tabPane').each(function () {
        let id = $(this).attr('id')
        $(this).removeClass('brigadeAccordion_tabPane tab-pane').addClass('brigadeAccordion_collapsePane collapse')

        // Transfer the active class to the collapse item if it was active in tabs
        if ($(this).hasClass('active')) {
          $(this).removeClass('active').addClass('show')
          $(`button[data-bs-target="#${id}"]`).removeClass('collapsed')
        } else {
          $(`button[data-bs-target="#${id}"]`).addClass('collapsed')
        }
      })
    } else {
      // If screen width is 991px or more, revert to tabs
      $('.brigadeAccordion_collapsePane').each(function () {
        let id = $(this).attr('id')
        $(this).removeClass('brigadeAccordion_collapsePane collapse').addClass('brigadeAccordion_tabPane tab-pane')

        // Remove the show class if it's not active
        if (!$(`[data-bs-target="#${id}"]`).hasClass('active')) {
          $(this).removeClass('show')
          $(`.nav-link[data-bs-target="#${id}"]`).removeClass('active')
        } else {
          $(this).removeClass('show').addClass('active')
          $(`.nav-link[data-bs-target="#${id}"]`).addClass('active')
        }
      })
    }
  }

  // Call the function on initial load
  toggleTabsAndAccordion()

  // Bind the function to the window resize event
  $(window).on('resize', function () {
    toggleTabsAndAccordion()
  })
})
