import $ from 'jquery'

window.addEventListener('DOMContentLoaded', () => {
  function showStep(step) {
    step.addClass('active')
    step.children('.bootstrapForm_fields').css('display','block')
  }

  if ($('#signUp').length) {
    const firstStep = $('.bootstrapForm_fieldset').first(),
      errors = $('.accountForm').data('errors'),
      firstStepFields = ['email', 'first_name', 'last_name', 'password', 'password_confirmation', 'profiles.organization_type_id']

    if (!errors.length) {
      showStep(firstStep)
    } else {
      if (errors.some(error => firstStepFields.indexOf(error) >= 0)) {
        showStep(firstStep)
      } else {
        showStep(firstStep.next())
        firstStep.addClass('filled')
      }
    }

    $('.next-step').click(function (e) {
      e.preventDefault()
      const currentStep = $(this).parents('.bootstrapForm_fieldset'),
        nextStep = currentStep.next()

      $('.bootstrapForm_fieldset').removeClass('active')
      currentStep.addClass('filled')
      currentStep.children('.bootstrapForm_fields').slideUp()

      nextStep.addClass('active')
      nextStep.children('.bootstrapForm_fields').slideDown({
        complete: function () {
          if(window.matchMedia('(max-width: 767px)').matches) {
            $('html, body').stop().animate({
              scrollTop: nextStep.offset().top - 70
            }, 250)
          } else {
            $('html, body').stop().animate({
              scrollTop: nextStep.offset().top - 110
            }, 250)
          }
        }
      })
    })

    $('.bootstrapForm_fieldset legend').click(function () {
      const currentStep = $(this).parents('.bootstrapForm_fieldset')

      if (currentStep.hasClass('filled') && !currentStep.hasClass('active')) {
        $('.bootstrapForm_fieldset').removeClass('active')
        currentStep.addClass('active')
        $('.bootstrapForm_fields').slideUp()
        $(this).siblings('.bootstrapForm_fields').slideDown()
      }
    })

    $('#sign_up').on('submit', (event) => {
      event.preventDefault()

      // Functions provided by recaptcha
      executeRecaptchaForRegistrationAsync().then((token) => {
        setInputWithRecaptchaResponseTokenForRegistration('g-recaptcha-response-data-registration', token)
        event.currentTarget.submit()
      })
    })
  }
})
