import $ from 'jquery'
import 'slick-carousel'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'

const debounce = (func, wait) => {
  let timeout
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout)
      func(...args)
    }
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
  }
}

const $slickSlider = (selector, customOptions = {}) => {
  const defaultOptions = {}
  const options = { ...defaultOptions, ...customOptions }

  const initializeSlider = () => {
    const slider = $(selector)
    if (!slider.length) {
      console.warn(`No element found for selector ${selector}`)
      return
    }

    if (!slider.hasClass('slick-initialized')) {
      slider.slick(options)
    }
  }

  $(document).ready(initializeSlider)

  $(window).on('resize', debounce(initializeSlider, 250))
}

window.$slickSlider = $slickSlider
