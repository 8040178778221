import $ from 'jquery'
import 'slick-carousel'

const $gallerySlider = (selector, additionalSliderOptions= {}) => {
  const defaultSliderOptions = {
    centerMode: true,
    centerPadding: '80px',
    infinite: true,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          centerMode: false,
          swipe: true,
        }
      },
    ],
    slidesToScroll: 1,
    slidesToShow: 1,
    swipe: false,
  }
  const setClass = (slick, cur) => {
    let next = cur.next(),
      prev = cur.prev()

    prev.addClass('slick-sprev')
    next.addClass('slick-snext')
    cur.removeClass('slick-snext').removeClass('slick-sprev')
    slick.$prev = prev
    slick.$next = next
  }

  const pauseVideo = () => {
    $('iframe').each(function() {
      $(this)[0].contentWindow.postMessage('{ "method": "pause","value": 1}', '*')
    })
  }

  $(selector).on('init', function (event, slick) {
    setClass(slick, $(slick.$slides[slick.currentSlide]))
  }).on('beforeChange', function (event, slick, currentSlide, nextSlide) {
    slick.$prev.removeClass('slick-sprev')
    slick.$next.removeClass('slick-snext')
    setClass(slick, $(slick.$slides[nextSlide]))

    $(selector).find('.video').length !== 0 && pauseVideo()
  })

  $(selector).slick(Object.assign(defaultSliderOptions, additionalSliderOptions))
}

window.$gallerySlider = $gallerySlider
